<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="ListIcon"
                  width="24"
                  height="24"
                />
                <h2>Model specifications</h2>
              </div>
              <b-form-group
                label="Name"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required|max:255"
                >
                  <b-form-input
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Type"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="type"
                    vid="type"
                  >
                    <v-select
                      v-model="form.type"
                      :options="typesList"
                      :reduce="type => type.id"
                      placeholder="Select type"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Manufacturer"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="manufacturer"
                    vid="manufacturer_id"
                  >
                    <v-select
                      v-model="form.manufacturer_id"
                      :options="manufacturersList"
                      :reduce="manufacturer => manufacturer.id"
                      placeholder="Select manufacturer"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Manufacturer Name"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="manufacturer_name"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.manufacturer_name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Manufacturer Name"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Product Code, SKU"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sku"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.sku"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Product Code, SKU"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="System Board Model"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="system_board_model"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.system_board_model"
                      :state="errors.length > 0 ? false:null"
                      placeholder="System Board Model"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="GlobeIcon"
                  width="24"
                  height="24"
                />
                <h2>Ports</h2>
              </div>
              <!-- loop for ports -->
              <div
                v-for="(port, index) in ports"
                :key="index"
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Interface"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="interface"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="port.interface"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Interface"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div
                  class="neighbouring-form-group with-button"
                >
                  <b-form-group
                    label="Usage"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usage"
                      rules="required|max:255"
                    >
                      <v-select
                        v-model="port.usage"
                        :options="portsTypes"
                        placeholder="Select usage"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <button
                    type="button"
                    class="remove-button"
                    @click="ports.splice(index, 1)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      width="20"
                      height="20"
                      color="#F0554E"
                    />

                  </button>
                </div>
              </div>
              <div class="fullWidthButton">
                <button
                  type="button"
                  @click="ports.push({ interface: '', usage: '' })"
                >
                  <feather-icon
                    icon="PlusIcon"
                    width="20"
                    height="20"
                    color="#F0554E"
                  />
                  Add another port
                </button>
              </div>
            </div>
            <div class="section-block  mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="FileIcon"
                  width="24"
                  height="24"
                />
                <h2>Config</h2>
              </div>

              <b-form-group
                label=""
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Model Name"
                  vid="list"
                >
                  <b-form-file
                    v-model="form.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".conf, .cfg"
                    :state="Boolean(form.file)"
                    class="upload-file"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormFile,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import axios from '../../axios-resolver'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      form: {
        name: '',
        type: null,
        manufacturer_id: null,
        file: null,
      },

      portsTypes: [
        'WAN',
        'LAN',
      ],

      ports: [
        {
          interface: '',
          usage: '',
        },
      ],

      required,
      max,
    }
  },
  computed: {
    ...mapFields('hardwareManufacturers', [
      'manufacturersList',
    ]),
    ...mapFields('hardwareModels', [
      'typesList',
    ]),
  },
  created() {
    this.getTypes()
    this.getManufacturersList()
  },
  methods: {
    ...mapActions('hardwareModels', [
      'getTypes',
    ]),
    ...mapActions('hardwareManufacturers', [
      'getManufacturersList',
    ]),
    async createItem(formData) {
      try {
        if (typeof this.form.file === 'object') {
          formData.append('file', this.form.file)
        }

        await axios.post('/hardware/model/store', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.goBack()
      } catch (error) {
        if (error.response && error.response.status === 422) {
          const { fields } = error.response.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.$refs.modelsCreate.setErrors(fields)
        }
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    async validationForm() {
      const valid = await this.$refs.modelsCreate.validate()

      if (valid) {
        try {
          const payloadFormData = new FormData()
          payloadFormData.append('name', this.form.name)
          payloadFormData.append('type', this.form.type)
          payloadFormData.append('manufacturer_name', this.form.type)
          payloadFormData.append('sku', this.form.sku)
          payloadFormData.append('system_board_model', this.form.system_board_model)
          payloadFormData.append('manufacturer_id', this.form.manufacturer_id)

          // form array like ports[0][usage]=LAN ports[0][interface]=ether1
          this.ports.forEach((port, index) => {
            payloadFormData.append(`ports[${index}][usage]`, port.usage)
            payloadFormData.append(`ports[${index}][interface]`, port.interface)
          })

          await this.createItem(payloadFormData)
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.modelsCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
